// Import libraries
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import { faArrowDown, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faSms } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'gatsby-image';



// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh - 15rem); /* 15rem is equal to Header in Header.js */

    & .overlay {
        display: grid;
        grid-template-rows: 1fr 6rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        align-items: center;
        justify-items: center;

        & .content {
            display: grid;
            grid-template-rows: repeat(2, min-content);
            grid-row-gap: 5rem;
            max-width: 1100px;
            margin-top: 100px; /* Equal to header height */
            padding: 0 var(--padding-right) 0 var(--padding-left);
            color: #fff;
            text-align: center;

            & .primary-color {
                color: var(--color-primary);
            }

            & h1 {
                font-size: var(--font-size-banner-h1);
                font-weight: var(--font-weight-banner-h1);
                line-height: var(--line-height-banner-h1);
            }

            & h2 {
                font-size: var(--font-size-banner-h2);
                font-weight: var(--font-weight-banner-h2);
                line-height: var(--line-height-banner-h2);
                margin-top: -4rem;
            }
            
            & p {
                font-size: 3rem;
                margin-top: -4rem;
            }

            &-link {
                justify-self: start;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                border-radius: 0;
                font-size: 2rem;
                padding: 1rem 2rem;
                color: #fff;
                font-weight: 400;
                margin: 0 auto;

                & span {
                    margin-right: 10px;
                }
            }

            &-link:link,
            &-link:visited {
                color: currentColor;
                text-decoration: none;
                outline: 0;
                transition: color .25s ease;
            }

            &-link:hover,
            &-link:focus {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(-3px);
                box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
            }

            &-link:active {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(0);
                box-shadow: none;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledCallMeBack = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 3rem;
    background-color: var(--color-background-3);
    padding: 5rem var(--padding-right) 5rem var(--padding-left);

    @media only screen and (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-row-gap: 5rem;
        justify-items: center;
    }

    &.bottom {
        padding-bottom: calc(var(--padding-right) + 42.5px); /* 42.5px is half of the height of the footer-scroll-to-top-button container located in the footer */
    }

    & .content {
        display: grid;
        grid-template-rows: repeat(2, min-content);
        color: #fff;

        @media only screen and (max-width: 900px) {
            text-align: center;
        }

        & h1 {
            font-size: 5rem;
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & h2 {
            font-size: 2rem;
            font-weight: 100;
            line-height: var(--line-height-body-h2);
            color: var(--color-primary);
        }
    }

    & form {
        display: grid;
        grid-template-rows: repeat(3, min-content);
        align-self: center;
        justify-self: center;
        grid-row-gap: 1rem;
        grid-template-columns: 300px;

        @media only screen and (max-width: 350px) {
            grid-template-columns: auto;
            width: 100%;
        }

        & input,
        & textarea {
            font-family: inherit;
            font-size: inherit;
            border-radius: 0;
            border: 1px solid #ccc;
            padding: 1rem 2rem;
            outline: none;
        }

        & textarea {
            resize: none;
        }
    }

    & .button {
        align-self: center;
        justify-self: center;
        display: flex;
        flex-direction: row;
        flex-direction: row;
        justify-self: stretch;
        justify-content: center;
        align-items: center;
        background-color: var(--color-primary);
        border: 2px solid var(--color-primary);
        border-radius: 0;
        font-size: 2rem;
        padding: 1rem 2rem;
        color: #fff;
        font-weight: 400;
        text-decoration: none;
        cursor: pointer;

        & span {
            margin-right: 10px;
        }
    }

    & .button:hover,
    & .button:focus {
        color: #fff;
        outline: 0;
        transform: translateY(-3px);
        box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
    }

    & .button:active {
        color: #fff;
        outline: 0;
        transform: translateY(0);
        box-shadow: none;
    }
`;

const StyledServices = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 5rem;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);

    & .content {
        display: grid;
        grid-template-rows: repeat(2, min-content);
        grid-row-gap: 1rem;
        text-align: center;
        max-width: 1100px;
        margin: 0 auto;

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & p {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }

    & .services {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 5rem;
        grid-row-gap: 5rem;
        max-width: 1600px;
        margin: 0 auto;

        @media only screen and (max-width: 1400px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media only screen and (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: 450px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    & .more-button {
        display: none;
        flex-direction: row;
        background-color: var(--color-primary);
        border: 2px solid var(--color-primary);
        border-radius: 0;
        font-size: 2rem;
        padding: 1rem 2rem;
        color: #fff;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        cursor: pointer;

        @media only screen and (max-width: 450px) {
            display: flex;
        }

        & span {
            margin-right: 10px;
        }
    }

    & .more-button:hover,
    & .more-button:focus {
        outline: 0;
        transform: translateY(-3px);
        box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
    }

    & .more-button:active {
        outline: 0;
        transform: translateY(0);
        box-shadow: none;
    }
`;

const StyledService = styled.div`
    position: relative;
    padding-bottom: 9rem;
    border: 1px solid var(--color-primary);

    @media only screen and (max-width: 450px) {
        &.hideOnMobile {
            display: none;
        }
    }

    & .service-header {
        display: grid;
        grid-template-rows: repeat(2, min-content);
        grid-row-gap: 1rem;
        align-content: center;
        justify-items: center;
        padding: 2rem 2rem 4rem;
        min-height: 275px;
        text-align: center;

        @media only screen and (max-width: 450px) {
            min-height: auto;
        }

        & .service-icon {
            width: 128px;
            height: 128px;

            @media only screen and (max-width: 500px) {
                width: 100px;
                height: 100px;
            }
        }

        & h2 {
            font-size: var(--font-size-body-h2);
            font-weight: var(--font-weight-body-h2);
            line-height: var(--line-height-body-h2);
        }
    }

    & .service-price {
        background-color: var(--color-primary);
        padding: 2rem;
        text-align: center;
        font-weight: bold;
        font-size: 2.5rem;

        & span span {
            font-size: 1.3rem;
        }
    }

    & .service-features {
        padding: 2rem 3rem;
        list-style: none;
        text-align: center;
        font-size: 1.8rem;

        & li {
            padding: .5rem 0;
        }
    }

    & .service-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 2rem;
        display: grid;
        grid-template-columns: repeat(3, min-content);
        grid-template-rows: 5rem;
        justify-content: center;
        grid-column-gap: 3rem;

        & .link {
            color: var(--color-primary);
            width: 5rem;
            height: 5rem;
            display: grid;
            align-items: center;
            justify-items: center;
            border: 3px solid var(--color-primary);
            border-radius: 50%;
            font-size: 2rem;

            &--sms {
                font-size: 2.5rem;
            }
        }

        & .link:link,
        & .link:visited {
            color: var(--color-primary);
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        & .link:hover,
        & .link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
            transform: translateY(-3px);
            box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
        }

        & .link:active {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;
            transform: translateY(0);
            box-shadow: none;
        }
    }
`;

const StyledCustomerTestimonials = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    grid-row-gap: 5rem;
    justify-items: center;
    background-color: var(--color-secondary);

    & .content {
        display: grid;
        grid-template-rows: repeat(2,min-content);
        padding-top: 10rem;
        text-align: center;
        color: #fff;
        border-bottom: 1px solid #fff;
        padding-bottom: 5rem;

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }
        
        & p {
            font-weight: var(--font-weight-body-p);
            font-size: var(--font-size-body-p);
            line-height: var(--line-height-body-p);
        }
    }

    & .slide {
        display: grid;
        grid-template-rows: repeat(3, min-content);
        grid-row-gap: 3rem;
        justify-items: center;
        align-content: center;
        padding: 0 var(--padding-right) 10rem var(--padding-left);
    }

    & .customer-testimonial-text {
        font-size: var(--font-size-body-p);
        font-weight: var(--font-weight-body-p);
        line-height: var(--line-height-body-p);
        max-width: 800px;
        text-align: center;
    }

    & .horizontal-divider {
        width: 200px;
        height: 1px;
        background-color: #fff;
    }

    & .customer-testimonial-name {
        font-size: var(--font-size-body-p);
        font-weight: var(--font-weight-body-p);
    }
`;

const carouselAnimation = keyframes`
    0%,
    28.33% { transform: translate3d(0%, 0px, 0px); }
    33.33%,
    61.66% { transform: translate3d(-100%, 0px, 0px); }
    66.66%,
    94.99% { transform: translate3d(-200%, 0px, 0px); }
    100% { transform: translate3d(0%, 0px, 0px); }
`;

/*
keyframe calculation
3 slides
determine duration per step: 5 seconds
total duration is 3 slides * 5 seconds = 15 seconds
3 slides means 3 waiting periods, so determine waiting period in % per slide, 5% in this case which is 3 slides * 5% = 15%
remaining percentage is 100% - 15% = 85%
85% / 3 slides = 28.33%

0%,
28.33% { transform: translate3d(0%, 0px, 0px); }
33.33%, --- 28.33% + 5% waiting period = 33%
61.66% { transform: translate3d(-100%, 0px, 0px); } --- 33.33% + 28.33% = 61.66%
66.66%, --- 61.66% + 5% waiting period = 66.66%
94.99% { transform: translate3d(-200%, 0px, 0px); } --- 66.66% + 28.33% = 94.99%
100% { transform: translate3d(0%, 0px, 0px); } --- 94.99% + 5% waiting period = 100%
*/

const StyledCarousel = styled.div`
    overflow: hidden;
    width: 100vw;
    min-height: 300px;

    & .slider {
        display: flex;
        height: 100%;
        position: relative;
        list-style: none;
        width: 100%;
        transform: translate3d(0%, 0px, 0px);
        animation: ${carouselAnimation} 24s linear infinite;

        & .slide {
            flex-direction: column;
            flex-flow: column;
            min-width: 100%;
            height: 100%;
            margin: 0;
            position: relative;
            text-align: center;
            background-color: var(--color-secondary);
            color: #fff;
        }
    }
`;

const StyledPaymentMethods = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    
    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }

    & .content {
        display: grid;
        grid-template-rows: repeat(3, min-content);
        grid-row-gap: 3rem;
        padding: var(--padding-right);
        text-align: center;

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }
        
        & p {
            font-weight: var(--font-weight-body-p);
            font-size: var(--font-size-body-p);
            line-height: var(--line-height-body-p);
        }
    }

    & .icons {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 3rem;
        justify-items: center;

        & .icon-container {
            
        }

        & .icon-container.payment-methods {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: 1fr;
            grid-column-gap: 3rem;
            width: 100%;
            align-items: center;
            justify-items: center;
        }

        & .icon--recycle {
            width: 75px;

            @media only screen and (max-width: 500px) {
                width: 50px;
            }
        }

        & .icon--payment-methods {
            width: 100px;

            @media only screen and (max-width: 500px) {
                width: 50px;
            }
        }

        & .icon--zip-pay {
            width: 150px;

            @media only screen and (max-width: 500px) {
                width: 75px;
            }
        }
    }
`;

const StyledWhyChooseUs = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    
    @media only screen and (max-width: 500px) {
        grid-template-columns: 1fr;
    }
    
    & .image {

    }

    & .content {
        display: grid;
        grid-template-rows: repeat(3, min-content);
        grid-row-gap: 3rem;
        padding: var(--padding-right);
        text-align: justify;

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }
        
        & p {
            font-weight: var(--font-weight-body-p);
            font-size: var(--font-size-body-p);
            line-height: var(--line-height-body-p);
        }
    }

    & .icons {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 3rem;
        justify-items: center;

        & .icon-container {
            
        }

        & .icon-container.payment-methods {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-auto-rows: 1fr;
            grid-column-gap: 3rem;
            width: 100%;
            align-items: center;
            justify-items: center;
        }

        & .icon--recycle {
            width: 75px;

            @media only screen and (max-width: 500px) {
                width: 50px;
            }
        }

        & .icon--payment-methods {
            width: 100px;

            @media only screen and (max-width: 500px) {
                width: 100px;
            }
        }

        & .icon--zip-pay {
            width: 150px;

            @media only screen and (max-width: 500px) {
                width: 200px;
            }
        }
    }
`;

const StyledContactUs = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);
    grid-row-gap: 5rem;
    background-color: var(--color-background-2);

    @media only screen and (max-width: 900px) {
        grid-template-columns: 1fr;
    }

    & .content {
        grid-column: 1 / -1;

        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }
    }
    
    & .contact {
        position: relative;
        display: grid;
        grid-template-rows: repeat(3, max-content);
        grid-row-gap: 5rem;
        justify-items: start;

        @media only screen and (max-width: 900px) {
            justify-items: center;
        }
        
        &-inner {
            display: grid;
            grid-template-rows: repeat(2, min-content);
            justify-items: start;
        }

        &-handwritten {
            font-family: 'Kalam', var(--font-family);
            font-size: 2.5rem;
            font-weight: 400;
            color: var(--color-primary);
        }

        &-link {
            display: grid;
            grid-template-columns: 5rem 1fr;
            grid-column-gap: 2rem;
            align-items: center;

            & .icon {
                display: grid;
                align-items: center;
                justify-items: center;
                width: 5rem;
                height: 5rem;
                border: 3px solid #000;
                border-radius: 50%;
                font-size: 2.5rem;
                transition: border-color .25s ease;

                &--sms {
                    font-size: 3rem;
                }
            }
        }
        
        &-link:link,
        &-link:visited {
            color: #000;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
            font-size: 3rem;
            font-weight: 100;
            cursor: pointer;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: none;
            color: var(--color-primary);
            outline: 0;

            & .icon {
                border-color: var(--color-primary);
            }
        }

        &-link:active {
            text-decoration: none;
            color: #000;
        }

        &-link--small:link,
        &-link--small:visited {
            font-weight: var(--font-weight-body-p);
            font-size: var(--font-size-body-p);
        }
    }

    & .contact-form {
        display: grid;
        grid-template-rows: repeat(2, min-content);
        grid-row-gap: .5rem;
        justify-items: center;
        width: 100%;

        &-handwritten {
            font-family: 'Kalam', var(--font-family);
            font-size: 2.5rem;
            font-weight: 400;
            color: var(--color-primary);
        }

        & form {
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            grid-row-gap: 1rem;

            & .form-field-wrapper {
                position: relative;
                background-color: #fff;
                z-index: 1;

                & .icon {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translate(0, -50%);
                    z-index: -1;
                }

                & .placeholder {
                    position: absolute;
                    top: 50%;
                    left: 2rem;
                    transform: translateY(-50%);
                    color: #777676;
                    z-index: -1;
                }
            }

            & input::placeholder,
            & select::placeholder,
            & textarea::placeholder {
                color: #777676;
            }

            & input,
            & select,
            & textarea {
                font-family: inherit;
                font-size: inherit;
                border-radius: 0;
                border: 1px solid #ccc;
                padding: 1rem 2rem;
                outline: none;
                width: 100%;
            }

            & textarea {
                resize: none;
            }

            & select {
                -webkit-appearance: none;
                padding-right: 3rem;
                background-color: transparent;
            }

            & .button {
                display: flex;
                flex-direction: row;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                border-radius: 0;
                font-size: 2rem;
                padding: 1rem 2rem;
                color: #fff;
                font-weight: 400;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                & span {
                    margin-right: 10px;
                }
            }

            & .button:hover,
            & .button:focus {
                outline: 0;
                transform: translateY(-3px);
                box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
            }

            & .button:active {
                outline: 0;
                transform: translateY(0);
                box-shadow: none;
            }
        }
    }
`;


// Create component
const IndexPage = () => {
    const [serviceType, setServiceType] = useState('');
    
    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "banner/index/banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            service1Icon: file(relativePath: { eq: "icons/Car-batteries.png" }) {
                childImageSharp {
                    fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            service2Icon: file(relativePath: { eq: "icons/Van-ute-suv-batteries.png" }) {
                childImageSharp {
                    fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            service3Icon: file(relativePath: { eq: "icons/Euro-start-stop-batteries.png" }) {
                childImageSharp {
                    fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            service4Icon: file(relativePath: { eq: "icons/Dual-system-batteries.png" }) {
                childImageSharp {
                    fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            service5Icon: file(relativePath: { eq: "icons/Truck-batteries.png" }) {
                childImageSharp {
                    fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            service6Icon: file(relativePath: { eq: "icons/Construction-batteries.png" }) {
                childImageSharp {
                    fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            service7Icon: file(relativePath: { eq: "icons/Caravan-batteries.png" }) {
                childImageSharp {
                    fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            service8Icon: file(relativePath: { eq: "icons/Marine-batteries.png" }) {
                childImageSharp {
                    fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            recycleIcon: file(relativePath: { eq: "icons/Recycle-icon.png" }) {
                childImageSharp {
                    fluid(maxWidth: 128, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            paymentMethodVisaIcon: file(relativePath: { eq: "icons/payment-methods/visa.png" }) {
                childImageSharp {
                    fluid(maxWidth: 512, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            paymentMethodMastercardIcon: file(relativePath: { eq: "icons/payment-methods/mastercard.png" }) {
                childImageSharp {
                    fluid(maxWidth: 512, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            paymentMethodEFTposIcon: file(relativePath: { eq: "icons/payment-methods/eftpos.png" }) {
                childImageSharp {
                    fluid(maxWidth: 512, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            paymentMethodZipPayIcon: file(relativePath: { eq: "icons/payment-methods/zip-pay.png" }) {
                childImageSharp {
                    fluid(maxWidth: 512, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            whyChooseUsImage: file(relativePath: { eq: "why-choose-us.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            site {
                siteMetadata {
                    phoneNumber
                    email
                }
            }
        }
    `);


    // Services
    const services = [
        {
            name: `Car & SUV Batteries`,
            price: `149,-`,
            features: [`Standard`, `Heavy Duty`, `Premium`],
            icon: data.service1Icon.childImageSharp.fluid,
            hideOnMobile: false
        },
        {
            name: `Vans, Utes, & 4x4 Batteries`,
            price: `149,-`,
            features: [`Standard`, `Heavy Duty`, `Deep Cycle`],
            icon: data.service2Icon.childImageSharp.fluid,
            hideOnMobile: false
        },
        {
            name: `Euro Start-Stop Batteries`,
            price: `199,-`,
            features: [`EFB`, `AGM`, `Lithium`],
            icon: data.service3Icon.childImageSharp.fluid,
            hideOnMobile: false
        },
        {
            name: `Dual Battery Systems`,
            price: `159,-`,
            features: [`Deep Cycle`, `AGM`, `Lithium`],
            icon: data.service4Icon.childImageSharp.fluid,
            hideOnMobile: true
        },
        {
            name: `Light & Heavy Truck Batteries`,
            price: `189,-`,
            features: [`Heavy Duty`, `N-Series`, `Hard Core`],
            icon: data.service5Icon.childImageSharp.fluid,
            hideOnMobile: true
        },
        {
            name: `Construction Batteries`,
            price: `149,-`,
            features: [`Standard`, `Gel`, `Premium`],
            icon: data.service6Icon.childImageSharp.fluid,
            hideOnMobile: true
        },
        {
            name: `Caravan AGM Batteries`,
            price: `199,-`,
            features: [`AGM`, `Gel`, `Premium`],
            icon: data.service7Icon.childImageSharp.fluid,
            hideOnMobile: true
        },
        {
            name: `Marine PWC Batteries`,
            price: `149,-`,
            features: [`AGM`, `Lithium`, `Deep Cycle`],
            icon: data.service8Icon.childImageSharp.fluid,
            hideOnMobile: true
        }
    ];


    // Handle scrolling down to below banner
    const handleScrollDown = () => {
        // Get banner height
        const headerHeight = document.querySelector('.header').offsetHeight;
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        
        // Scroll to place underneath banner
        window.scrollTo({
            top: headerHeight + bannerHeight,
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Home" />
            <Layout>
                <StyledBanner
                    className="banner"
                    image={data.bannerImage}
                >
                    <div className="overlay">
                        <div className="content">
                            <h1>Mobile Battery Replacement</h1>
                            <h2>Servicing Brisbane to Gold Coast & Sunshine Coast.</h2>
                            <p>Roadside, homeside, rescue with <span className="primary-color">ZERO callout fees</span>.</p>
                            
                            <a href="/#contact" className="content-link">Contact us</a>
                        </div>

                        <div className="scroll-down-button" onClick={handleScrollDown}>
                            <FontAwesomeIcon icon={faArrowDown} className="icon" />
                        </div>
                    </div>
                </StyledBanner>

                <StyledPaymentMethods>
                    <div className="content">
                        <h1>Payment Methods</h1>
                        <p>We accept eftpos and all major credit cards. We also offer Zip Pay to all our customers.</p>

                        <div className="icons">
                            <div className="icon-container payment-methods">
                                <Img fluid={data.paymentMethodVisaIcon.childImageSharp.fluid} alt="Visa" className="icon icon--payment-methods" />
                                <Img fluid={data.paymentMethodMastercardIcon.childImageSharp.fluid} alt="Mastercard" className="icon icon--payment-methods" />
                                <Img fluid={data.paymentMethodEFTposIcon.childImageSharp.fluid} alt="eftpos" className="icon icon--payment-methods" />
                                <Img fluid={data.paymentMethodZipPayIcon.childImageSharp.fluid} alt="Zip Pay" className="icon icon--payment-methods icon--zip-pay" />
                            </div>
                        </div>
                    </div>
                </StyledPaymentMethods>

                <StyledCallMeBack id="call-me-back-1">
                    <div className="content">
                        <h1>Let us call you back</h1>
                        <h2>Leave your number here and we will call you back ASAP.</h2>
                    </div>

                    <form name="call-me-back-top" action="/success?type=call-me-back" method="POST" enctype="application/x-www-form-urlencoded" data-netlify="true">
                        <input type="hidden" name="form-name" value="call-me-back-top" />
                        <input type="text" name="phone-number" placeholder="Leave your number" required />
                        <textarea name="message" placeholder="Your message (optional)" rows="3"></textarea>
                        <button type="submit" className="button">
                            <span>Call Me Back</span>
                            <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                        </button>
                    </form>
                </StyledCallMeBack>

                <StyledServices className="services">
                    <div className="content">
                        <h1>Battery Replacement Services</h1>
                        <p>We come to you! Large majority of callouts attended to in under 1 hour! Our mobile workshops stock an extensive range of batteries suitable for most vehicle makes and models. We replace your battery in no time.</p>
                    </div>

                    <div className="services">
                        {services.map(service => (
                            <StyledService key={service.name} className={service.hideOnMobile ? "hideOnMobile" : ""}>
                                <div className="service-header">
                                    <Img fluid={service.icon} alt={service.name} className="service-icon" />
                                    <h2>{service.name}</h2>
                                </div>

                                <div className="service-price">
                                    <span><span>from</span> &#36;{service.price}</span>
                                </div>

                                <ul className="service-features">
                                    {service.features.map(feature => (<li key={feature}>{feature}</li>))}
                                </ul>

                                <div className="service-footer">
                                    <a href={`tel:${data.site.siteMetadata.phoneNumber}`} className="link" aria-label="Call us">
                                        <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
                                    </a>
                                    <a href={`sms:${data.site.siteMetadata.phoneNumber}`} className="link link--sms" aria-label="SMS us">
                                        <FontAwesomeIcon icon={faSms} className="icon" />
                                    </a>
                                    <a href="/#contact"  className="link" aria-label="Email us" onClick={() => setServiceType(service.name)}>
                                        <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                    </a>
                                </div>
                            </StyledService>
                        ))}
                    </div>

                    <button className="more-button" onClick={(e) => {
                        document.querySelectorAll('.services .hideOnMobile').forEach(service => service.classList.remove('hideOnMobile'));
                        e.target.style.display = 'none';
                    }}>More Battery Types</button>
                </StyledServices>

                <StyledCustomerTestimonials>
                    <div className="content">
                        <h1>Customer Testimonials</h1>
                        <p>See for yourself what our customers have to say about us.</p>
                    </div>

                    <StyledCarousel>
                        <ul className="slider">
                            <li className="slide">
                                <p className="customer-testimonial-text">&#34;I have used this mobile service for my family & company cars for the past several years and without exception the experiences have been outstanding. I would highly recommend this place to any one who wants great service, honest value, and really great people.&#34;</p>
                                <div className="horizontal-divider">&nbsp;</div>
                                <span className="customer-testimonial-name">Robert Smith</span>
                            </li>
                            <li className="slide">
                                <p className="customer-testimonial-text">&#34;I am so happy that I used and found Barrie. He did a excellent job and was so eager to please even when I asked him to do another job for me while he was here. He has been great with the communication with me and he always replied to my questions or messages. I definitely will be using Barrie again.&#34;</p>
                                <div className="horizontal-divider">&nbsp;</div>
                                <span className="customer-testimonial-name">Kaye</span>
                            </li>
                            <li className="slide">
                                <p className="customer-testimonial-text">&#34;Barrie was wonderful and very understanding with my situation. Fix the car in no time. I have already recommended him to everyone one I know needing a battery, mechanic or auto electrician.&#34;</p>
                                <div className="horizontal-divider">&nbsp;</div>
                                <span className="customer-testimonial-name">Shelly</span>
                            </li>
                        </ul>
                    </StyledCarousel>
                </StyledCustomerTestimonials>

                <StyledWhyChooseUs>
                    <Img fluid={data.whyChooseUsImage.childImageSharp.fluid} alt="" className="image" />
                    <div className="content">
                        <h1>Why Choose Us?</h1>
                        <p>At All River Batteries, our mobile workshops stock an extensive range of batteries suitable for most vehicle makes and models.</p>
                        <p>When it comes to our battery replacement services we only work with brands that are proven and trusted within our industry. All batteries we supply have been tested for quality and are manufactured with leading industry technology. Fitted by a qualified mechanic or auto electrician. All this gives you a great quality product, which is fitted correctly, with all vehicle systems tested, giving you maximum battery life.</p>
                        <p>We are conscious of our environment. All our batteries are recyclable and we recycle all used batteries responsibly. We accept eftpos and all major credit cards. We also offer Zip Pay to all our customers.</p>

                        <div className="icons">
                            <div className="icon-container">
                                <Img fluid={data.recycleIcon.childImageSharp.fluid} alt="Recyclable batteries" className="icon icon--recycle" />
                            </div>
                            <div className="icon-container payment-methods">
                                <Img fluid={data.paymentMethodVisaIcon.childImageSharp.fluid} alt="Visa" className="icon icon--payment-methods" />
                                <Img fluid={data.paymentMethodMastercardIcon.childImageSharp.fluid} alt="Mastercard" className="icon icon--payment-methods" />
                                <Img fluid={data.paymentMethodEFTposIcon.childImageSharp.fluid} alt="eftpos" className="icon icon--payment-methods" />
                                <Img fluid={data.paymentMethodZipPayIcon.childImageSharp.fluid} alt="Zip Pay" className="icon icon--payment-methods icon--zip-pay" />
                            </div>
                        </div>
                    </div>
                </StyledWhyChooseUs>

                <StyledContactUs id="contact">
                    <div className="content">
                        <h1>Choose your preferred contact method</h1>
                    </div>

                    <div className="contact">
                        <div className="contact-inner">
                            <p className="contact-handwritten">Call us</p>
                            <a href={`tel:${data.site.siteMetadata.phoneNumber}`} className="contact-link" id="call-contact-us" aria-label="Call us">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                </div>
                                <span>{data.site.siteMetadata.phoneNumber}</span>
                            </a>
                        </div>

                        <div className="contact-inner">
                            <p className="contact-handwritten">Text us</p>
                            <a href={`sms:${data.site.siteMetadata.phoneNumber}`} className="contact-link" id="sms-contact-us" aria-label="Text us">
                                <div className="icon icon--sms">
                                    <FontAwesomeIcon icon={faSms} />
                                </div>
                                <span>{data.site.siteMetadata.phoneNumber}</span>
                            </a>
                        </div>

                        <div className="contact-inner">
                            <p className="contact-handwritten">Email us</p>
                            <a href={`mailto:${data.site.siteMetadata.email}`} target="_blank" rel="noopener noreferrer" className="contact-link" aria-label="Email us">
                                <div className="icon">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <span>{data.site.siteMetadata.email}</span>
                            </a>
                        </div>
                    </div>
                    
                    <div className="contact-form">
                        <p className="contact-form-handwritten">Fill out the contact form</p>
                        <form name="contact-form" action="/success?type=contact-form" method="POST" enctype="application/x-www-form-urlencoded" data-netlify="true">
                            <input type="hidden" name="form-name" value="contact-form" />
                            <input type="text" name="name" placeholder="Name" required />
                            <input type="text" name="phone-number" placeholder="Phone number" required />
                            <input type="text" name="email" placeholder="Email" required />
                            <div className="form-field-wrapper">
                                <select name="service-type" value={serviceType} onChange={(e) => setServiceType(e.target.value)}>
                                    <option></option>
                                    {services.map(service => (
                                        <option value={service.name} key={service.name}>{service.name}</option>
                                    ))}
                                </select>
                                <FontAwesomeIcon icon={faCaretDown} className="icon" />
                                <span className="placeholder" hidden={serviceType !== '' ? true : false}>Service Type</span>
                            </div>
                            <textarea name="message" placeholder="Message" rows="6" required></textarea>
                            <button type="submit" className="button">
                                <span>Send</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </button>
                        </form>
                    </div>
                </StyledContactUs>

                <StyledCallMeBack className="bottom" id="call-me-back-2">
                    <div className="content">
                        <h1>Let us call you back</h1>
                        <h2>Leave your number here and we will call you back ASAP.</h2>
                    </div>

                    <form name="call-me-back-bottom" action="/success?type=call-me-back" method="POST" enctype="application/x-www-form-urlencoded" data-netlify="true">
                        <input type="hidden" name="form-name" value="call-me-back-bottom" />
                        <input type="text" name="phone-number" placeholder="Leave your number" required />
                        <textarea name="message" placeholder="Your message (optional)" rows="3"></textarea>
                        <button type="submit" className="button">
                            <span>Call Me Back</span>
                            <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                        </button>
                    </form>
                </StyledCallMeBack>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default IndexPage;